import React from 'react';

import Row from 'react-bootstrap/Row';

export default function FAQs() {

    return (
        <div className='bg-light mt-4 mb-4 p-3'>
            <Row>
                <h1>Frequently Asked Questions</h1>
            </Row>
            <Row className='mt-3 mb-3 p-3'>

                <div class="table-responsive">
                    <table class="table text-left">

                    <tbody>
                        <tr>
                            <td style={ {"width": "30%"} }><strong>How would you describe HealthRate?</strong></td>
                            <td style={ {"width": "70%"} }>HealthRate is a sophisticated, real-time, decision support platform empowering users to make fact-based decisions every time by putting powerful analysis techniques in the hands of your users with very simple inputs that anyone can use.</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Why are the costs for pre-administration different for contracts with the same payment terms?</strong>
                            </td>
                            <td>
                                The cost of each phase is impacted by many contractual factors, for example if the clinical scenario results in a Minimum Payment Provision triggering, the “real cost” of the pre-administration phase will be different than the percent of charges or other primary payment terms.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>How do I know which clinical severity to select?</strong>
                            </td>
                            <td>
                            Please reference the HealthRate system documentation and end user guides for a detailed explanation regarding clinical severity considerations and impacts.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>HealthRate is missing several key terms (e.g. Organ Acquisition, Lesser Of, Living Donor, Professional Fees, Access Fees, etc.). Why aren't these considered?</strong>
                            </td>
                            <td>
                                HealthRate considers ALL contractual elements and payment terms.  However, only a small number are available to the end user for modification, custom modeling, and hypothetical analysis. Additional inputs may be available depending on you licensing, please contact your system administrator or HealthRate account representative for further details.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>I save a higher percentage of billed charges already. Why would I use these rates?</strong>
                            </td>
                            <td>
                                Savings numbers presented are accretive savings and compare ALLOWED amounts to ALLOWED amounts. For example if HealthRate is showing a network with 20% savings – the 20% is incremental to any savings off of billed charges you may already be realizing (typically in the 40% range).
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>As a First Dollar Payer (Employer, Healthplan, etc.), I don't have access to multiple networks.</strong>
                            </td>
                            <td>
                            This is likely not the case.
                            <ol>
                            <li>The scope of HealthRate is specialty/care out contracts such as those used for transplants, oncology, neonatal, and novel therapies (CGT).  If you are currently paying for these services under standard contract terms a VERY large and IMMEDIATE opportunity exits to reduce your claims costs.  More likely you’re already utilizing one or more of the existing specialty networks such as Optum Transplant Services, Cigna LifeSource, Aetna Institutes of Excellence, Multiplan, Blue Distinctions, etc). These networks are rarely exclusive and It is by evaluating these networks (and others) against each other on a case by case basis that the greatest savings can be captured.</li>
                            <li>In the unlikely event that you are restricted to a single network (specialty or otherwise) your vendor partners (TPA, stop loss, reinsurance, broker, etc.) are almost certainly not and they can leverage HealthRate to reduce your claims costs and premium costs.</li>
                            <li>Finally, if you manage your own specialty network(s), or wish to work with a single network provider HealthRate can be used to benchmark and audit your networks rates at very granular levels including but not limited to:  clinical severity, service type, service sub type, within providers, across providers, by geography, most favored nation status, etc.</li>
                            </ol>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>What data does HealthRate provide and utilize that is unique?</strong>
                            </td>
                            <td>
                            HealthRate provides the following:
                            <ol>
                            <li>Clinical Severity Distribution Data - based on analysis of 25+years of data covering nearly 300M lives HealthRate has developed tiers of severity that are correlated to real world data billed charges levels.</li>
                            <li>Transparency Data - HealthRate has harvested, refined, and interpreted market wide data from the No Suprises and Transparency in Coverage acts and applied them in a meaningful and specific way to determine actual costs.  Simply publishing code based rates without context is of limited use and often times misleading.</li>
                            <li>Contract & Payment Data - HeathRate's contract ingestion process and proprietary data model structure your specific payment terms for use with chargemasters and other comprehensive rate information to determine contextual and relative payment performance.</li>
                            <li>HealthRate Data - HealthRate's algorithms generate a propriety, comprehensive and on demand data set of ACTUAL cost outlays for specific service types, at specific providers for specific patient scenarios.  This data is normalized to provide true apples to apples comparisons on case by case, regional, and national levels.</li>
                            </ol>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Is HealthRate secure?</strong>
                            </td>
                            <td>
                                HealthRate secures transactions and information using end-to-end encryption with data encrypted in-flight and at rest. HealthRate does not accept Personal Health Information (PHI) and no patient data is accepted or stored. HealthRate aligns with industry security standards of National Institute of Standards and Technology (NIST) and Open Web Application Security Project (OWASP) and routinely subjects the application to internal and external testing.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>What platforms are supported on HealthRate?</strong>
                            </td>
                            <td>HealthRate supports Workstations or Laptops: Windows (10+) or Linux; Mobile Devices: Apple IPad, IPhone, Android and Microsoft Surface.</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </Row>
        </div>
    )
}
