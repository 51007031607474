import React from 'react';

import Row from 'react-bootstrap/Row';

import { Send } from 'react-bootstrap-icons';

export default function Support() {

    return (
        <div className='bg-light mt-4 mb-4 p-3'>
            <Row>
                <h1>Support</h1>
            </Row>
            <Row>
                <a href="mailto:support@healthrate.io"><Send />&nbsp;support@healthrate.io</a>
            </Row>
        </div>
    )

}
