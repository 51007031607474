import React, { useState } from 'react';

import Alert from 'react-bootstrap/Alert';

import logo from '../../assets/healthrate.io_vertical.svg'

import './Login.css';

async function apiv1LoginWithCredentials(credentials) {
    return fetch('/api/v1/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
}

export default function Login({ setToken }) {

    const [alertError, setAlertError] = useState(null)

    const [credentials, setCredentials] = useState({
        username: ''
    ,   password: ''
    })

    const handleLoginSubmit = async e => {

        e.preventDefault();

        const r = await apiv1LoginWithCredentials(credentials);

        if (r.status === 200) {
            setAlertError(null);
            r.json().then(t =>
                setToken(t['token'])
            )
        } else {
            setToken('');
            setCredentials({
                username: credentials.username
            ,   password: ''
            })
            r.text().then(t =>
                setAlertError({
                    title: 'Status Code: ' + r.status + ' ' + r.statusText
                    , body: t.replace(/\n/g, "<br />")
                })
            )
        }
    }

    return (
        <div className="container bg-light rounded mt-5">

            <div className="p-5 text-center">
                <img src={logo} className="login-logo" alt="HealthRate Logo" />
            </div>

            <div className="row justify-content-center pb-5">
                <div className="col-md-6">

                    <Alert show={alertError} variant="danger">
                        <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
                        <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>

                        {/* <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={() => setShow(false)} variant="outline-error">
                        Close me
                    </Button>
                </div> */}

                    </Alert>


                    <form id="form_login" onSubmit={handleLoginSubmit}>
                        <div className="mb-3">
                            <label htmlFor="login_username" className="form-label">Username</label>
                            <input value={credentials.username} onChange={e => setCredentials({ username: e.target.value, password: credentials.password })} type="text" className="form-control" id="login_username" name="username" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="login_password" className="form-label">Password</label>
                            <input value={credentials.password} onChange={e => setCredentials({ username: credentials.username, password: e.target.value })} type="password" className="form-control" id="login_password" name="password" required />
                        </div>
                        <button type="submit" className="btn btn-primary">Login</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
