import React, { useEffect, useRef } from 'react';
import Plotly from 'plotly.js/dist/plotly.min.js';

const PlotlyChart = ({ isVisible, data, layout, chartHeight }) => {

    const chartRef = useRef(null);
    const chartImgContainerRef = useRef(null);
    const chartImgRef = useRef(null);

    useEffect(() => {
        Plotly.newPlot(chartRef.current, data, layout);

        Plotly.toImage(chartRef.current, {width: 950 , height: 500})
        .then(function(url) {
            var img = new Image();
            img.src = url;
            chartImgRef.current.src = url;
        });

        const handleResize = () => {
            Plotly.Plots.resize(chartRef.current);
        };
        window.addEventListener('resize', handleResize);

        const handleBeforePrint = () => {
            console.log('before print');
            chartImgContainerRef.current.style.display = 'block';
            chartRef.current.style.display = 'none';
        };
        window.addEventListener('beforeprint', handleBeforePrint);

        const handleAfterPrint = () => {
            console.log('after print');
            chartRef.current.style.display = 'block';
            chartImgContainerRef.current.style.display = 'none';
        }
        window.addEventListener('afterprint', handleAfterPrint);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('beforeprint', handleBeforePrint);
            window.removeEventListener('afterprint', handleAfterPrint);
            if (chartRef.current) {
                Plotly.purge(chartRef.current);
            }
        };
    }, []);

    useEffect(() => {
        Plotly.Plots.resize(chartRef.current);
    }, [isVisible]);

    return (
        <div className={isVisible ? '' : 'd-none'}>
            <div 
                ref={ chartRef }
                style={{ width: '100%', height: chartHeight }} 
            />
            <div 
                ref={ chartImgContainerRef }
                style={{ width: '100%', display: 'none' }}
            >
                <img 
                    ref={ chartImgRef }
                    style={{ width: '100%', height: chartHeight }}
                />
            </div>
        </div>
    );
};

export default PlotlyChart;
