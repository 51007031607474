import React, { useState, useEffect, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import SimulationServiceTypeSelect from '../Simulation/SimulationServiceTypeSelect';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';

export default function InternationalQuote({ token, onLogout }) {
    const [internationalQuoteInput, setInternationalQuoteInput] = useState({
        serviceType: null,
        serviceTypeCategory: null,
        adultPediatric: 'A'
    });

    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]); // Updated for badges
    const [alertError, setAlertError] = useState(null);

    const setServiceTypeCategory = (serviceTypeCategory) => {
        setInternationalQuoteInput((prev) => ({
            ...prev,
            serviceTypeCategory,
            serviceType: null,
        }));
    };

    const setServiceType = (serviceType) => {
        setInternationalQuoteInput((prev) => ({
            ...prev,
            serviceType,
        }));
    };

    const fetchCountries = useCallback(async () => {
        try {
            const res = await fetch('/api/v1/international_quote', {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
            });
            const data = await res.json();
            data.countries = (data.countries || []).sort((a, b) => a.country.localeCompare(b.country));

            if (res.status === 200) {
                setCountries((data.countries || []));
            } else if (res.status === 401) {
                onLogout();
            } else {
                setAlertError({
                    title: data.name,
                    body: data.description?.replace(/\n/g, '<br />') || 'An error occurred.',
                });
            }
        } catch (error) {
            setAlertError({
                title: 'Error Fetching Countries',
                body: 'Could not load the country list. Please try again later.',
            });
        }
    }, [token, onLogout]);

    const removeCountry = (countryToRemove) => {
        setSelectedCountries((prev) =>
            prev.filter((country) => country !== countryToRemove)
        );
    };

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

    return (        
        <>
        <Alert show={alertError} variant="danger">
            <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
            <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
        </Alert>
        <div className="bg-light mt-4 mb-4 p-3">
            <Row>
                <h1>International Quote</h1>
            </Row>
            <Row>
                <p>HealthRate has established relationships with a number of prestigious medical centers worldwide and may be able to secure <strong>fixed-price bids</strong> for your case(s). By choosing this option, you could save between <strong>40-60% compared to U.S.-based medical centers</strong>.</p>
                <p>Please complete the form below, and one of our experts will promptly contact you to explain the process and gather additional details about your case(s).</p>
                <p>What Service Type are you interested in?</p>
            </Row>
            <Row>
                <SimulationServiceTypeSelect
                    element_id="serviceType"
                    token={token}
                    onLogout={onLogout}
                    simulationInput={internationalQuoteInput}
                    setServiceTypeCategory={setServiceTypeCategory}
                    setServiceType={setServiceType}
                />
            </Row>
            <Row className="mt-3">
                <p>Which Countries are you interested in comparing?</p>
                <div className="col-md-6" style={{ marginBottom: '1em' }}>
                    <select
                        className="form-select"
                        value=""
                        onChange={(e) => {
                            if (e.target.value) {
                                setSelectedCountries((prev) => [...prev, e.target.value]);
                            }
                        }}
                    >
                        <option value="">Select a country...</option>
                        {countries
                            .filter((country) => !selectedCountries.includes(country.country)) // Filter out already selected countries
                            .map((country) => (
                                <option key={country.international_quote_id} value={country.country}>
                                    {country.country}
                                </option>
                            ))}
                    </select>
                </div>

                <div>
                    {selectedCountries.map((country, index) => (
                        <Badge
                            key={index}
                            pill
                            bg="primary"
                            className="m-1"
                            style={{ cursor: 'pointer' }}
                            onClick={() => removeCountry(country)}
                        >
                            {country} &times;
                        </Badge>
                    ))}
                </div>
            </Row>
            <Row className="mt-3">
                <div className="d-flex justify-content-start" style={{ marginLeft: '540px' }}>
                    <button
                        onClick={() => {
                            const emailBody = `Service Type Category: ${ 
                                internationalQuoteInput.serviceTypeCategory?.service_type_category || 'None'
                            }\nService Type: ${
                                internationalQuoteInput.serviceType?.service_type || 'None'
                            }\nCountries: ${selectedCountries.join(', ') || 'None'}`;
                            window.open(
                                `mailto:international@healthrate.io?subject=International Quote&body=${encodeURIComponent(
                                    emailBody
                                )}`
                            );
                        }}
                        className="btn btn-primary"
                        style={{ width: '100px' }}
                        disabled={
                            !internationalQuoteInput.serviceTypeCategory ||
                            !internationalQuoteInput.serviceType ||
                            selectedCountries.length === 0  
                        }
                    >
                        Submit
                    </button>
                </div>
            </Row>
            <style>
                {`
                    .adult-pediatric-buttons,
                    label[for^="radio-"] {
                        visibility: hidden !important;
                        pointer-events: none !important;
                    }
                `}
            </style>
        </div>
    </>
    );
}
