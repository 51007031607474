import { useState } from 'react';


export default function useSimulationInput() {

    const currentVersion = 4;

    const defaultSimulationInput = {
        adultPediatric: 'A'
    ,   serviceTypeCategory: null
    ,   serviceType: null
    ,   severity: null
    ,   providers: []
    ,   applyIndex: false
    ,   version: currentVersion
    };

    const getSimulationInput = () => {

        console.log('loading simulation_input')

        try {
            const simulation_input = JSON.parse(localStorage.getItem('simulation_input'));
            const r = simulation_input  && simulation_input.version && simulation_input.version === currentVersion ? simulation_input : JSON.parse(JSON.stringify(defaultSimulationInput));
            return r;
        } catch {
            return JSON.parse(JSON.stringify(defaultSimulationInput))
        }

    };

    const [simulationInput, setSimulationInput4Hook] = useState(getSimulationInput());

    const setSimulationInput = (simulationInputNew) => {
        simulationInputNew['version'] = currentVersion;
        console.log('setting simulation_input');
        localStorage.setItem('simulation_input', JSON.stringify(simulationInputNew));
        setSimulationInput4Hook(simulationInputNew);
    };

    const getUserDefinedProviderCount = () => {

        console.log('loading user_defined_provider_count')

        try {
            const user_defined_provider_count = JSON.parse(localStorage.getItem('user_defined_provider_count'));
            const r = user_defined_provider_count ? user_defined_provider_count : 0;
            return r;
        } catch {
            return 0;
        }

    };

    const [userDefinedProviderCount, setUserDefinedProviderCount4Hook] = useState(getUserDefinedProviderCount());

    const setUserDefinedProviderCount = (userDefinedProviderCountNew) => {
        console.log('setting user_defined_provider_count');
        localStorage.setItem('user_defined_provider_count', JSON.stringify(userDefinedProviderCountNew));
        setUserDefinedProviderCount4Hook(userDefinedProviderCountNew);
    };

    const onSimulationReset = () => {
        localStorage.setItem('simulation_input', JSON.stringify(defaultSimulationInput));
        setSimulationInput4Hook(JSON.parse(JSON.stringify(defaultSimulationInput)));
        localStorage.setItem('user_defined_provider_count', 0);
        setUserDefinedProviderCount4Hook(0);
    };

    return {
        setSimulationInput
    ,   simulationInput
    ,   setUserDefinedProviderCount
    ,   userDefinedProviderCount
    ,   onSimulationReset
    ,   currentVersion
    }

}
