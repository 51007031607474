import React, { useState } from 'react';

import { PlusCircle, QuestionCircle, XCircle } from 'react-bootstrap-icons'

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';

import { NumericFormat } from "react-number-format";

export default function SimulationProvider({ element_id, token, onLogout, provider, onProviderUpdate, onProviderDelete, handleNetworkAdd }) {

    const findUserDefinedNetworkCount = (provider) => {

        const userDefinedNetworks = provider['networks'].filter((n) => typeof n['network_id'] === 'string' && n['network_id'].startsWith('user_defined_'));

        if (userDefinedNetworks.length === 0)
            return 0;

        const highest_match = Math.max(...userDefinedNetworks.map((n) => {
            const matches = n['network_id'].match(/user_defined_(\d+)$/);
            return parseInt(matches[1]);
        }));

        return highest_match + 1;
    }

    const [userDefinedNetworkCount, setUserDefinedNetworkCount] = useState(findUserDefinedNetworkCount(provider));

    const onNetworkDelete = (provider, network) => {

        provider.networks = provider.networks.filter((n) => {
            return n.network_id !== network.network_id;
        });

        onProviderUpdate(provider);
    }

    const handleNetworkChangeField = (provider, network, field, value) => {

        provider.networks = provider.networks.map((n) => { 

            if (n.network_id !== network.network_id) {
                return n;
            }

            const n_new = network;
            n_new[field] = value;
            return n_new;
        });

        onProviderUpdate(provider);
    }

    const onAddUserDefinedNetwork = () => {

        setUserDefinedNetworkCount(userDefinedNetworkCount + 1);

        provider['networks'].push({
            "provider_id": provider.provider_id
        ,   "provider": provider.provider
        ,   "provider_abbr": provider.provider_abbr
        // ,   "service_type_id":1
        // ,   "service_type":"Liver"
        ,   "network_id": `user_defined_${userDefinedNetworkCount}`
        ,   "network": `User Defined Network #${userDefinedNetworkCount + 1}`
        ,   "admin_case_rate":150000
        ,   "facility_pct_charge":100
        ,   "biologic_rate_pct_wac":100
        ,   "outlier_threshold":500000
        ,   "outlier_pct_charge":70
        ,   "mpp_pct_charge":0
        ,   "days_included":20
        ,   "per_diem_rate":0
        ,   "escalator_pct":0
        });

        onProviderUpdate(provider);
    }

    return (
        <div className="border-top pt-2 pb-2">

            <Row>
                <Col xs={12}>
                    <h2><Button onClick={() => onProviderDelete(provider)} variant="outline-danger" ><XCircle /></Button> { provider['provider'] }</h2>
                    <h4 style={{ marginLeft: '2.2em', paddingBottom: '0.8em' }}>HealthRate Institution Index&trade; ({ provider['provider_cost_index'] }) </h4>
                </Col>
            </Row>

            {provider['networks'].map((n) => (
                <Row style={{ paddingBottom: '1.2em' }} key={ `${element_id}_${provider.provider_id}_${n.network_id}` }>
                    <Col xs={1}></Col>
                    <Col xs={11}>
                        <h3><Button onClick={() => onNetworkDelete(provider, n)} variant="outline-danger" ><XCircle /></Button> { n['network'] }</h3>

                        <div className="row">

                            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
                                <label htmlFor={ `${element_id}_${provider.provider_id}_${n.network_id}_admin_case_rate` } className="form-label">Case Rate</label>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 600, hide: 400 }}
                                    overlay={
                                        <Tooltip id="tooltip-caseRate" className='custom-tooltip'>
                                            Expressed in dollars, the amount to be paid for the primary therapy/transplant/procedure.
                                        </Tooltip>
                                    }
                                >
                                <span>&nbsp;<QuestionCircle id="qc-caseRate" /></span>
                                </OverlayTrigger>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <NumericFormat
                                        className="form-control" 
                                        value={ n.admin_case_rate }
                                        onValueChange={(values, sourceInfo) => {
                                            handleNetworkChangeField(provider, n, 'admin_case_rate', parseInt(values.floatValue));
                                        }}
                                        onBlur={ (e) => { if (e.target.value === "") { handleNetworkChangeField(provider, n, 'case_rate', 0) } } }
                                        id={ `${element_id}_${provider.provider_id}_${n.network_id}_admin_case_rate` } 
                                        placeholder="10,000"
                                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                                    <span className="input-group-text">.00</span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
                                <label htmlFor={ `${element_id}_${provider.provider_id}_${n.network_id}_facility_pct_charge` } className="form-label">Facility Rate</label>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 600, hide: 400 }}
                                    overlay={
                                        <Tooltip id="tooltip-facilityRate" className='custom-tooltip'>
                                            Expressed as a percentage of charges, the rate at which all charges will be paid at (unless otherwise specified).
                                        </Tooltip>
                                    }
                                >
                                <span>&nbsp;<QuestionCircle id="qc-facilityRate" /></span>
                                </OverlayTrigger>
                                
                                <div className="input-group">
                                    <NumericFormat
                                        className="form-control" 
                                        value={ n.facility_pct_charge } 
                                        onValueChange={(values, sourceInfo) => {
                                            handleNetworkChangeField(provider, n, 'facility_pct_charge', parseFloat(values.floatValue));
                                        }}
                                        onBlur={ (e) => { if (e.target.value === "") { handleNetworkChangeField(provider, n, 'facility_pct_charge', 0.0) } } }
                                        id={ `${element_id}_${provider.provider_id}_${n.network_id}_facility_pct_charge` } 
                                        placeholder="60"
                                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                                    <span className="input-group-text">%</span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
                                <label htmlFor={ `${element_id}_${provider.provider_id}_${n.network_id}_biologic_rate_pct_wac` } className="form-label">Biologic Rate as a % of WAC</label>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 600, hide: 400 }}
                                    overlay={
                                        <Tooltip id="tooltip-bioPctWAC" className='custom-tooltip'>
                                            Expressed as a percentage of the Wholesale Acquisition Cost "WAC". The payment rate of the relevant therapy, commonly only relevant for novel therapies such as cell and gene.
                                        </Tooltip>
                                    }
                                >
                                <span>&nbsp;<QuestionCircle id="qc-bioPctWAC" /></span>
                                </OverlayTrigger>
                                <div className="input-group">
                                    <NumericFormat
                                        className="form-control" 
                                        value={ n.biologic_rate_pct_wac }
                                        onValueChange={(values, sourceInfo) => {
                                            handleNetworkChangeField(provider, n, 'biologic_rate_pct_wac', parseFloat(values.floatValue));
                                        }}
                                        onBlur={ (e) => { if (e.target.value === "") { handleNetworkChangeField(provider, n, 'biologic_rate_pct_wac', 0.0) } } }
                                        id={ `${element_id}_${provider.provider_id}_${n.network_id}_biologic_rate_pct_wac` } 
                                        placeholder="60"
                                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                                    <span className="input-group-text">%</span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
                                <label htmlFor={ `${element_id}_${provider.provider_id}_${n.network_id}_outlier_threshold` } className="form-label">Outlier Charge Threshold</label>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 600, hide: 400 }}
                                    overlay={
                                        <Tooltip id="tooltip-outlierChgThres" className='custom-tooltip'>
                                            Expressed in dollars, the amount (if any) at which charges will be paid at an alternative rate.
                                        </Tooltip>
                                    }
                                >
                                <span>&nbsp;<QuestionCircle id="qc-outlierChgThres" /></span>
                                </OverlayTrigger>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <NumericFormat
                                        className="form-control" 
                                        value={ n.outlier_threshold }
                                        onValueChange={(values, sourceInfo) => {
                                            handleNetworkChangeField(provider, n, 'outlier_threshold', parseInt(values.floatValue));
                                        }}
                                        onBlur={ (e) => { if (e.target.value === "") { handleNetworkChangeField(provider, n, 'outlier_threshold', 0) } } }
                                        id={ `${element_id}_${provider.provider_id}_${n.network_id}_outlier_threshold` } 
                                        placeholder="10,000"
                                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                                    <span className="input-group-text">.00</span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
                                <label htmlFor={ `${element_id}_${provider.provider_id}_${n.network_id}_outlier_pct_charge` } className="form-label">Outlier Rate % Charge</label>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 600, hide: 400 }}
                                    overlay={
                                        <Tooltip id="tooltip-outlierRtPctChg" className='custom-tooltip'>
                                            Expressed as a percentage of charges, the rate at which all charges above a certain threshold will be paid.
                                        </Tooltip>
                                    }
                                >
                                <span>&nbsp;<QuestionCircle id="qc-outlierRtPctChg" /></span>
                                </OverlayTrigger>                                
                                <div className="input-group">
                                    <NumericFormat
                                        className="form-control" 
                                        value={ n.outlier_pct_charge } 
                                        onValueChange={(values, sourceInfo) => {
                                            handleNetworkChangeField(provider, n, 'outlier_pct_charge', parseFloat(values.floatValue));
                                        }} 
                                        onBlur={ (e) => { if (e.target.value === "") { handleNetworkChangeField(provider, n, 'outlier_pct_charge', 0.0) } } }
                                        id={ `${element_id}_${provider.provider_id}_${n.network_id}_outlier_pct_charge` } 
                                        placeholder="60"
                                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                                    <span className="input-group-text">%</span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
                                <label htmlFor={ `${element_id}_${provider.provider_id}_${n.network_id}_mpp_pct_charge` } className="form-label">Minimum Payment Provision</label>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 600, hide: 400 }}
                                    overlay={
                                        <Tooltip id="tooltip-mpp_pct_charge" className='custom-tooltip'>
                                            Expressed as a percentage of charges, any provision that exists regarding overall minimum payments.
                                        </Tooltip>
                                    }
                                >
                                <span>&nbsp;<QuestionCircle id="qc-mpp_pct_charge" /></span>
                                </OverlayTrigger>
                                <div className="input-group">
                                    <NumericFormat
                                        className="form-control" 
                                        value={ n.mpp_pct_charge } 
                                        onValueChange={(values, sourceInfo) => {
                                            handleNetworkChangeField(provider, n, 'mpp_pct_charge', parseFloat(values.floatValue));
                                        }} 
                                        onBlur={ (e) => { if (e.target.value === "") { handleNetworkChangeField(provider, n, 'mpp_pct_charge', 0.0) } } }
                                        id={ `${element_id}_${provider.provider_id}_${n.network_id}_mpp_pct_charge` } 
                                        placeholder="60"
                                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                                    <span className="input-group-text">%</span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
                                <label htmlFor={ `${element_id}_${provider.provider_id}_${n.network_id}_days_included` } className="form-label">Inlier Days</label>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 600, hide: 400 }}
                                    overlay={
                                        <Tooltip id="tooltip-inlierDays" className='custom-tooltip'>
                                            Expressed as a whole number. The number of days included in the case rate.
                                        </Tooltip>
                                    }
                                >
                                <span>&nbsp;<QuestionCircle id="qc-inlierDays" /></span>
                                </OverlayTrigger>
                                <div className="input-group">
                                    <NumericFormat
                                        className="form-control" 
                                        value={ n.days_included } 
                                        onValueChange={(values, sourceInfo) => {
                                            handleNetworkChangeField(provider, n, 'days_included', parseInt(values.floatValue));
                                        }}
                                        onBlur={ (e) => { if (e.target.value === "") { handleNetworkChangeField(provider, n, 'days_included', 0) } } }
                                        id={ `${element_id}_${provider.provider_id}_${n.network_id}_days_included` } 
                                        placeholder="20"
                                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                                    <span className="input-group-text">days</span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
                                <label htmlFor={ `${element_id}_${provider.provider_id}_${n.network_id}_per_diem_rate` } className="form-label">Per Diem</label>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 600, hide: 400 }}
                                    overlay={
                                        <Tooltip id="tooltip-perDiem" className='custom-tooltip'>
                                            Expressed in dollars, the amount (if any) at which hospitalization will be covered for days beyond the Inlier Days.
                                        </Tooltip>
                                    }
                                >
                                <span>&nbsp;<QuestionCircle id="qc-perDiem" /></span>
                                </OverlayTrigger>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <NumericFormat
                                        className="form-control" 
                                        value={ n.per_diem_rate }
                                        onValueChange={(values, sourceInfo) => {
                                            handleNetworkChangeField(provider, n, 'per_diem_rate', parseInt(values.floatValue));
                                        }}
                                        onBlur={ (e) => { if (e.target.value === "") { handleNetworkChangeField(provider, n, 'per_diem_rate', 0) } } }
                                        id={ `${element_id}_${provider.provider_id}_${n.network_id}_per_diem_rate` } 
                                        placeholder="10,000"
                                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                                    <span className="input-group-text">.00</span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
                                <label htmlFor={ `${element_id}_${provider.provider_id}_${n.network_id}_escalator_pct` } className="form-label">Escalator</label>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 600, hide: 400 }}
                                    overlay={
                                        <Tooltip id="tooltip-escalator" className='custom-tooltip'>
                                            Expressed as a percentage, the rate at which the Case Rate is periodically increased due to inflation or contract terms.
                                        </Tooltip>
                                    }
                                >
                                <span>&nbsp;<QuestionCircle id="qc-escalator" /></span>
                                </OverlayTrigger>
                                <div className="input-group">
                                    <NumericFormat
                                        className="form-control" 
                                        value={ n.escalator_pct }
                                        onValueChange={(values, sourceInfo) => {
                                            handleNetworkChangeField(provider, n, 'escalator_pct', parseFloat(values.floatValue));
                                        }}
                                        onBlur={ (e) => { if (e.target.value === "") { handleNetworkChangeField(provider, n, 'escalator_pct', 0.0) } } }
                                        id={ `${element_id}_${provider.provider_id}_${n.network_id}_escalator_pct` } 
                                        placeholder="3"
                                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                                    <span className="input-group-text">%</span>
                                </div>
                            </div>
                            
                        </div>

                    </Col>
                </Row>
            ))}

            <Row style={{ paddingBottom: '1.2em' }}>
                <Col>
                    {typeof provider.provider_id === 'number' ? (
                        <Button onClick={() => handleNetworkAdd(provider)} >
                            <PlusCircle /> Add Networks
                        </Button>
                    ) : (<></>)}
                    &nbsp;
                    <Button onClick={() => onAddUserDefinedNetwork()} variant="secondary" >
                        <PlusCircle /> Add User Defined Network
                    </Button>
                </Col>
            </Row>

        </div>
    );
}

