import React, { useRef, useState } from 'react';

import { QuestionCircle } from 'react-bootstrap-icons'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { NumericFormat } from "react-number-format";

export default function SimulationSeverityParams({ element_id, token, onLogout, simulationInput, setSeverity }) {


    const isEnabled = () =>  {
        return simulationInput && 'severity' in simulationInput && simulationInput['severity'];
    };

    const handleOnChangeSeverity = (k, v) => {
        if (simulationInput['severity'] !== null) {
            const severity = Object.assign({}, simulationInput['severity']);
            severity[k] = v;
            setSeverity(severity);
        }
    };
    
    return (
        <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
                <label htmlFor="evaluationCost" className="form-label">Evaluation Cost</label>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 600, hide: 400 }}
                    overlay={
                        <Tooltip id="tooltip-evalCost" className='custom-tooltip'>
                            Expressed in dollars, any costs charged before case rates and contractual terms apply. Examples include charges for preliminary diagnosis or comorbidities.
                        </Tooltip>
                    }
                >
                <span>&nbsp;<QuestionCircle id="qc-evalCost" /></span>
                </OverlayTrigger>
                
                <div className="input-group">
                    <span className="input-group-text">$</span>
                    <NumericFormat  
                        className="form-control" 
                        disabled={ !isEnabled() } 
                        value={ !isEnabled() ? '' : simulationInput['severity']['eval_billed_mean'] }
                        onValueChange={(values, sourceInfo) => {
                            handleOnChangeSeverity('eval_billed_mean', parseInt(values.floatValue));
                        }}
                        onBlur={ (e) => { if (e.target.value === "") { handleOnChangeSeverity('eval_billed_mean', 0) } } }
                        id="evaluationCost" 
                        placeholder="10,000"
                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                    <span className="input-group-text">.00</span>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
            <label htmlFor="preAdministrationCost" className="form-label">Pre-Administration Cost</label><OverlayTrigger
                    placement="top"
                    delay={{ show: 600, hide: 400 }}
                    overlay={
                        <Tooltip id="tooltip-preAdmin" className='custom-tooltip'>
                            Expressed in dollars, all charges related to the case prior to administration of the therapy/transplant/procedure.
                        </Tooltip>
                    }
                >
                <span>&nbsp;<QuestionCircle id="qc-preAdmin" /></span>
                </OverlayTrigger>
                <div className="input-group">
                    <span className="input-group-text">$</span>
                    <NumericFormat 
                        className="form-control" 
                        disabled={ !isEnabled() } 
                        value={ !isEnabled() ? '' : simulationInput['severity']['pre_admin_billed_mean'] }
                        onValueChange={(values, sourceInfo) => {
                            handleOnChangeSeverity('pre_admin_billed_mean', parseInt(values.floatValue));
                        }}
                        onBlur={ (e) => { if (e.target.value === "") { handleOnChangeSeverity('pre_admin_billed_mean', 0) } } }
                        id="preAdministrationCost"
                        placeholder="10,000"
                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                    <span className="input-group-text">.00</span>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
            <label htmlFor="administrationCost" className="form-label">Transplant / Administration Cost</label>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 600, hide: 400 }}
                    overlay={
                        <Tooltip id="tooltip-adminCost" className='custom-tooltip'>
                            Expressed in dollars, all charges related to the case during administration of the therapy/transplant/procedure. Note not all costs are included in case rates.
                        </Tooltip>
                    }
                >
                <span>&nbsp;<QuestionCircle id="qc-admin" /></span>
                </OverlayTrigger>
                <div className="input-group">
                    <span className="input-group-text">$</span>
                    <NumericFormat  
                        className="form-control" 
                        disabled={ !isEnabled() } 
                        value={ !isEnabled() ? '' : simulationInput['severity']['admin_billed_mean'] } 
                        onValueChange={(values, sourceInfo) => {
                            handleOnChangeSeverity('admin_billed_mean', parseInt(values.floatValue));
                        }}
                        onBlur={ (e) => { if (e.target.value === "") { handleOnChangeSeverity('admin_billed_mean', 0) } } }
                        id="administrationCost" 
                        placeholder="10,000"
                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                    <span className="input-group-text">.00</span>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
            <label htmlFor="postAdministrationCost" className="form-label">Post-Administration Cost</label>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 600, hide: 400 }}
                    overlay={
                        <Tooltip id="tooltip-postAdmin" className='custom-tooltip'>
                            Expressed in dollars, all charges related to the case after the administration of the therapy/transplant/procedure.
                        </Tooltip>
                    }
                >
                <span>&nbsp;<QuestionCircle id="qc-postAdmin" /></span>
                </OverlayTrigger>
                
                <div className="input-group">
                    <span className="input-group-text">$</span>
                    <NumericFormat
                        className="form-control" 
                        disabled={ !isEnabled() } 
                        value={ !isEnabled() ? '' : simulationInput['severity']['post_admin_billed_mean'] } 
                        onValueChange={(values, sourceInfo) => {
                            handleOnChangeSeverity('post_admin_billed_mean', parseInt(values.floatValue));
                        }}
                        onBlur={ (e) => { if (e.target.value === "") { handleOnChangeSeverity('post_admin_billed_mean', 0) } } }
                        id="postAdministrationCost" 
                        placeholder="10,000"
                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                    <span className="input-group-text">.00</span>
                </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
            <label htmlFor="additionalCost" className="form-label">Additional Cost</label>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 600, hide: 400 }}
                    overlay={
                        <Tooltip id="tooltip-addtlCost" className='custom-tooltip'>
                            Expressed in dollars, any costs excluded from case rates and contractual terms. Examples include charges for unrelated conditions, some follow ups or comorbidities.
                        </Tooltip>
                    }
                >
                <span>&nbsp;<QuestionCircle id="qc-addtlCost" /></span>
                </OverlayTrigger>                
                <div className="input-group">
                    <span className="input-group-text">$</span>
                    <NumericFormat
                        className="form-control" 
                        disabled={ !isEnabled() } 
                        value={ !isEnabled() ? '' : simulationInput['severity']['additional_billed_mean'] } 
                        onValueChange={(values, sourceInfo) => {
                            handleOnChangeSeverity('additional_billed_mean', parseInt(values.floatValue));
                        }}
                        onBlur={ (e) => { if (e.target.value === "") { handleOnChangeSeverity('additional_billed_mean', 0) } } }
                        id="additionalCost" 
                        placeholder="10,000"
                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                    <span className="input-group-text">.00</span>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
            <label htmlFor="biologicWACCost" className="form-label">Biologic</label>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 600, hide: 400 }}
                    overlay={
                        <Tooltip id="tooltip-bioWAC" className='custom-tooltip'>
                            Expressed in dollars, the Wholesale Acquisition Cost "WAC" of the relevant therapy, commonly only relevant for novel therapies such as cell and gene.
                        </Tooltip>
                    }
                >
                <span>&nbsp;<QuestionCircle id="qc-bioWAC" /></span>
                </OverlayTrigger>
                <div className="input-group">
                    <span className="input-group-text">$</span>
                    <NumericFormat  
                        className="form-control" 
                        disabled={ !isEnabled() } 
                        value={ !isEnabled() ? '' : simulationInput['severity']['biologic_billed'] } 
                        onValueChange={(values, sourceInfo) => {
                            handleOnChangeSeverity('biologic_billed', parseInt(values.floatValue));
                        }}
                        onBlur={ (e) => { if (e.target.value === "") { handleOnChangeSeverity('biologic_billed', 0) } } }
                        id="biologicWACCost" 
                        placeholder="10,000"
                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                    <span className="input-group-text">.00</span>
                </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-2 mb-2">
            <label htmlFor="inlierDaysIncluded" className="form-label">Length of Stay</label>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 600, hide: 400 }}
                    overlay={
                        <Tooltip id="tooltip-inlierDays" className='custom-tooltip'>
                            Expressed as a whole number. The number of days the patient is hospitalized.
                        </Tooltip>
                    }
                >
                <span>&nbsp;<QuestionCircle id="qc-inlierDays" /></span>
                </OverlayTrigger>
                <div className="input-group">
                    <NumericFormat
                        className="form-control" 
                        disabled={ !isEnabled() } 
                        value={ !isEnabled() ? '' : simulationInput['severity']['length_of_stay_mean'] } 
                        onValueChange={(values, sourceInfo) => {
                            handleOnChangeSeverity('length_of_stay_mean', parseInt(values.floatValue));
                        }} 
                        onBlur={ (e) => { if (e.target.value === "") { handleOnChangeSeverity('length_of_stay_mean', 0) } } }
                        id="inlierDaysIncluded" 
                        placeholder="20" 
                        thousandSeparator="," allowNegative={ false } decimalScale={ 0 } />
                    <span className="input-group-text">days</span>
                </div>
            </div>

        </div>

    );
}

