import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import useFormatters from '../../hooks/useFormatters';


export default function RateSheet({ token, onLogout, setChatBotContext }) {

    const [alertError, setAlertError] = useState(null);

    const [networks, setNetworks] = useState([]);
    const [networksTextFilter, setNetworksTextFilter] = useState('');

    const { provider_id, network_id, service_type_id } = useParams();

    useEffect(() => {

        if (networks.length === 1) {
            
            let new_chat_bot_context = "";

            new_chat_bot_context += `For Service Type ${networks[0]['service_type']}, Provider ${networks[0]['provider']} with Network ${networks[0]['network']} has the following Contract Information: Administration Case Rate: ${networks[0]['admin_case_rate']}; Facility Rate: ${networks[0]['facility_pct_charge']}; Biologic Rate as a % of WAC: ${networks[0]['biologic_rate_pct_wac']}; Outlier Charge Threshold: ${networks[0]['outlier_threshold']}; Outlier % Charge: ${networks[0]['outlier_pct_charge']}; Minimum Payment Provision: ${networks[0]['mpp_pct_charge']}; Inlier Days: ${networks[0]['days_included']}; and Per Diem: ${networks[0]['per_diem_rate']}.`;

            setChatBotContext(new_chat_bot_context);
            console.log('setting chat bot context...' + JSON.stringify(new_chat_bot_context))    
        }

    }, [networks]);

    const fetchNetworks = useCallback( () => {
        
        const do_fetch = async () => {

            const fff = {};

            let fetch_url = '/api/v1/networks?' + new URLSearchParams(fff);

            console.log('fetching networks: ' + fetch_url);

            const res = await fetch(fetch_url, { method: 'GET', headers: { Authorization: 'Bearer ' + token }});
            const data = await res.json();

            if (res.status === 200) {
                if (Array.isArray(data['networks'])) {
                    
                    const _networks = data['networks'].map((n) => {
                        // n['facility_pct_charge'] = n['facility_pct_charge'] * 100;
                        // n['biologic_rate_pct_wac'] = n['biologic_rate_pct_wac'] * 100;
                        // n['outlier_pct_charge'] = n['outlier_pct_charge'] * 100;
                        // n['mpp_pct_charge'] = n['mpp_pct_charge'] * 100;
                        return n;
                    })
                    .filter((n) => {
                        if (provider_id && network_id && service_type_id)
                            return n['provider_id'] === parseInt(provider_id) && n['network_id'] === parseInt(network_id) && n['service_type_id'] === parseInt(service_type_id);
                        else
                            return true;
                    })

                    const provider_ids = [...new Set(_networks.map((n) => n['provider_id']))];

                    console.log('loaded networks for ' + JSON.stringify(fff) + ' : ' + data['networks'].length);

                    try {

                        const provider_promises = provider_ids.map(async (provider_id) => {

                            let fetch_url = `/api/v1/providers/${provider_id}`;

                            console.log('fetching provider: ' + fetch_url);

                            const res = await fetch(fetch_url, { method: 'GET', headers: { Authorization: 'Bearer ' + token }});
                            const data = await res.json();

                            if (res.status === 200) {
                                if (Array.isArray(data['providers'])) {
                                    console.log(`loaded provider data for ${provider_id}`);
                                    return data['providers'][0];
                                }
                            }
                            return null;
                        });
        
                        const providerResults = await Promise.all(provider_promises);

                        const _providers = {};
                        for (let i = 0; i < providerResults.length; i++) {
                            _providers[providerResults[i]['provider_id']] = providerResults[i];
                        }

                        setNetworks(_networks.map((n) => {
                            n['name_full'] = `${_providers[n['provider_id']]['provider']} : ${n['network']} : ${n['service_type']}`;
                            return n;
                        }).sort((a, b) => {
                            if (a.name_full < b.name_full)
                                return -1;
                            else if (a.name_full > b.name_full)
                                return 1;
                            else
                                return 0;
                        }));

                    } catch (error) {
                        console.error("Error fetching providers:", error);
                    }

                } 
                else {
                    setNetworks({...fff
                    ,   networks: []
                    });
                    console.log(`WARNING no loaded networks for ${JSON.stringify(fff)}`);
                }
            }
            else if (res.status === 401) {
                console.log('logging out due to bad token (401)');
                onLogout();
            }
            else {
                console.log(JSON.stringify(data));
                setAlertError({
                    title: data.name
                ,   body: data.description.replace(/\n/g, "<br />")
                });
            }
        }

        console.log('calling useEffect ' + JSON.stringify({
            token, network_id
        }));

        do_fetch();

    }, [token, onLogout, provider_id, network_id, service_type_id]);

    useEffect(() => {
        fetchNetworks();
    }, [fetchNetworks]);

    // ************************************************************************
    // Formatters

    const { currencyFormatter, percentageFormatter} = useFormatters();

    return (

        <>

        <Alert show={alertError} variant="danger">
            <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
            <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
        </Alert>

        { (network_id && networks.length > 0 )   ? (
            
            <div className='bg-light mt-4 mb-4 p-3'>
                
                <Row className='mb-3 d-print-none' >
                    <a href={ `/rate_sheets` }>&lt; All</a>
                </Row>

                {networks.map((n, n_idx) => (
                    <>

                    <Row className='mb-3'>
                        <h1>{ n['provider'] }</h1>
                        <h2>{ n['network'] }</h2>
                        <h3>{ n['service_type'] }</h3>
                        &nbsp;&nbsp;
                        <hr></hr>
                        &nbsp;&nbsp;
                    </Row>

                    <Row className='mb-3'>
                        <h4>Pre-Administration</h4>
                        <p>This phase includes all inpatient and outpatient transplant-related health services provided to a patient beginning at evaluation and ending at the start of the transplant administration phase. Donor testing and search/match fees are part of this phase.</p>
                    </Row>

                    <Row className='mb-3'>
                        <h4>Rates Pre-Administration</h4>

                        <div class="table-responsive">
                            <table class="table text-center">

                            <thead class="table-secondary">
                                <tr>
                                <th scope="col" style={ {"width": "30%"} }>Qualifier</th>
                                <th scope="col" style={ {"width": "36%"} }>Rate</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Facility</td>
                                    <td>{ percentageFormatter.format(n['facility_pct_charge']) }</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </Row>

                    <Row className='mb-3'>
                        <h4>Transplant Administration</h4>

                        <p>The administration phase includes all inpatient and outpatient transplant-related health services provided to a patient and living donor beginning the day of the transplant procedure and ending at discharge or transfer to a rehabilitation unit or facility.</p>
                        <p>The case rate includes facility and professional transplant care services and supplies provided during the administration phase, as well as organ procurement and transport provided during the pre-administration and administration phases. If the patient remains inpatient when the length of stay has elapsed, payment for additional inpatient days shall be made at per diem rates if applicable. If the patient is readmitted during the administration phase, the transplant terms will apply. In the event total allowed exceeds the outlier threshold, payment for additional allowed over the outlier threshold will be made at the outlier rate.</p>
                        <p>Retransplantation that occurs during the initial transplant admission is payable at 50% of the transplant case rate. A new period of length of stay will begin on the day of retransplantation. If a patient is readmitted for retransplantation within 30 days of original transplant discharge the retransplantation is payable at 50% of the transplant administration case rate. A new transplant administration phase will begin on the day of retransplantation.</p>
                        <p>In the even that billed charges are less than the case rate and outlier rates, the case rate and outlier rates shall not apply and payment shall be made at 95% of billed charges.</p>
                    </Row>

                    <Row className='mb-3'>
                        <h4>Rates Transplant</h4>

                        <div class="table-responsive">
                            <table class="table text-center">

                            <thead class="table-secondary">
                                <tr>
                                <th scope="col" style={ {"width": "30%"} }>Qualifier</th>
                                <th scope="col" style={ {"width": "36%"} }>Rate</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Facility &amp; Professional</td>
                                    <td>deceased or living donor</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </Row>

                    <Row className='mb-3'>
                        <h4>Provisions</h4>

                        <div class="table-responsive">
                            <table class="table text-center">

                            <thead class="table-secondary">
                                <tr>
                                <th scope="col" style={ {"width": "30%"} }>Qualifier</th>
                                <th scope="col" style={ {"width": "36%"} }>Rate</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Organ/Marrow Included</td>
                                    <td>Yes</td>
                                </tr>
                                { n['admin_case_rate'] ? (
                                    <tr>
                                        <td>Case Rate</td>
                                        <td>{ currencyFormatter.format(n['admin_case_rate']) }</td>
                                    </tr>
                                ) : (<></>)}
                                { n['biologic_rate_pct_wac'] ? (
                                    <tr>
                                        <td>Biologic Rate as a % of WAC</td>
                                        <td>{ n['biologic_rate_pct_wac'] === 999999 ? 'Not Defined' : percentageFormatter.format(n['biologic_rate_pct_wac']) }</td>
                                    </tr>
                                ) : (<></>)}
                                { n['outlier_threshold'] ? (
                                    <tr>
                                        <td>Outlier Charge Threshold</td>
                                        <td>{ currencyFormatter.format(n['outlier_threshold']) }</td>
                                    </tr>
                                ) : (<></>)}
                                { n['outlier_pct_charge'] ? (
                                    <tr>
                                        <td>Outlier Rate % Charge</td>
                                        <td>{ percentageFormatter.format(n['outlier_pct_charge']) }</td>
                                    </tr>
                                ) : (<></>)}
                                <tr>
                                    <td>Minimum Payment (MPP)</td>
                                    <td>{ n['mpp_pct_charge'] ? percentageFormatter.format(n['mpp_pct_charge']) : 'No' }</td>
                                </tr>
                                <tr>
                                    <td>Days Included</td>
                                    <td>{ n['days_included'] }</td>
                                </tr>
                                { n['per_diem_rate'] ? (
                                    <tr>
                                        <td>Per Diem</td>
                                        <td>{ currencyFormatter.format(n['per_diem_rate']) }</td>
                                    </tr>
                                ) : (<></>)}
                            </tbody>
                            </table>
                        </div>

                    </Row>

                    <Row className='mb-3'>
                        <h4>Post-Administration</h4>

                        <p>This phase includes all inpatient and outpatient transplant-related health services provided to a patient during the 12-month period immediately following the end of the transplant phase.</p>
                    </Row>

                    <Row className='mb-3'>

                        <h4>Rates Post-Transplant</h4>

                        <div class="table-responsive">
                            <table class="table text-center">

                            <thead class="table-secondary">
                                <tr>
                                <th scope="col" style={ {"width": "30%"} }>Qualifier</th>
                                <th scope="col" style={ {"width": "36%"} }>Rate</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Facility</td>
                                    <td>{ percentageFormatter.format(n['facility_pct_charge']) }</td>
                                </tr>
                            </tbody>

                            </table>
                        </div>

                    </Row>

                </>
                ))}

            </div>

        ) : (
        
            <div className='bg-light mt-4 mb-4 p-3'>

                <Row className='mb-3'>
                    <h1>Rate Sheets</h1>
                </Row>

                <Row className='mb-3'>
                    <div className="input-group">
                        <span className="input-group-text">Search</span>
                        <input type="text" className="form-control" value={ networksTextFilter } onChange={ (e) => setNetworksTextFilter(e.target.value) } id={ 'rate_sheets_textFilter' } />
                    </div>
                </Row>


                <ul>
                    {networks
                    .map((n) => {

                        n['search_found'] = 0;

                        if (networksTextFilter === '') {
                            n['search_found'] = 1;
                            return n;
                        }

                        let filters = networksTextFilter.split(' ');

                        for (var i = 0; i < filters.length; i++) {

                            if (filters[i] === '')
                                continue;

                            let re = new RegExp(filters[i].replace(/[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g, '\\$&'), 'i');
                            const result = n['name_full'].match(re);
                            if (result) 
                                n['search_found'] += 1;
                        }

                        return n;

                    })
                    .filter((n) => n['search_found'] > 0)
                    .sort((a, b) => {
                        if (a['search_found'] !== b['search_found']) {
                            return a['search_found'] > b['search_found'] ? -1 : 1;
                        }
                        return a['name_full'] > b['name_full'] ? 1 : -1;
                    })
                    .map((n, n_idx) => (
                        <li key={ `${n['network_id']}_${n_idx}` }>
                            <ul><a href={ `/rate_sheets/${ n['provider_id'] }/${ n['network_id'] }/${ n['service_type_id'] }` }>{ n['name_full'] }</a></ul>
                        </li>
                    ))}
                </ul>

            </div>
        )}

        </>

    )

}

