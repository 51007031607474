import React, { useCallback, useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

export default function SimulationServiceTypeSelect({ element_id, token, onLogout, simulationInput, setAdultPediatric, setServiceTypeCategory, setServiceType }) {

    const [alertError, setAlertError] = useState(null)

    const [serviceTypeCategories, setServiceTypeCategories] = useState([]);

    const radiosAdultPediatric = [
        { name: 'Adult', value: 'A' },
        { name: 'Pediatric', value: 'P' },
    ];
    
    const handleAdultPediatricChange = async e => {
        
        if (simulationInput['serviceType'] !== null & e.target.value !== simulationInput['adultPediatric'] ) {
            const s = (simulationInput['serviceTypeCategory'] === null ? [] : simulationInput['serviceTypeCategory']['service_types'])
            .filter((t) => t['adult_pediatric'] === e.target.value)
            .map((t) => {

                t['search_found'] = 0;

                let filters = simulationInput['serviceType']['service_type'].split(' ');

                for (var i = 0; i < filters.length; i++) {

                    if (filters[i] === '')
                        continue;

                    let re = new RegExp(filters[i].replace(/[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g, ''), 'i');
                    const result = t['service_type'].match(re);
                    if (result) 
                        t['search_found'] += 1;
                }

                return t;

            })
            .filter((t) => t['search_found'] > 0)
            .sort((a, b) => {
                if (a['search_found'] > b['search_found']) {
                    return -1;
                } else if (a['search_found'] < b['search_found']) {
                    return 1;
                } else {
                    return 0;
                }
            });

            if (s.length > 0) {
                delete s[0]['search_found'];
                    setAdultPediatric(e.target.value, s[0]);
            }
            else {
                setAdultPediatric(e.target.value, null);
            }
        }
        else {
            setAdultPediatric(e.target.value, null);
        }

    };
    
    const handleOnChangeServiceTypeCategory = async e => {
        if (e.target.value === "null") {
            setServiceTypeCategory(null);
        } 
        else {
            const service_type_category_id = parseInt(e.target.value);
            const s = serviceTypeCategories.filter((service_type_category) => service_type_category['service_type_category_id'] === service_type_category_id);
            setServiceTypeCategory(s[0]);
        }
    };

    const handleOnChangeServiceType = async e => {
        if (e.target.value === "null") {
            setServiceType(null);
        } 
        else {
            const service_type_id = parseInt(e.target.value);
            const s = (simulationInput['serviceTypeCategory'] === null ? [] : simulationInput['serviceTypeCategory']['service_types']).filter((service_type) => service_type['service_type_id'] === service_type_id);
            setServiceType(s[0]);
        }
    };

    const fetchServiceTypes = useCallback( async () => {
        const res = await fetch('/api/v1/service_types', { method: 'GET', headers: { Authorization: 'Bearer ' + token }});
        const data = await res.json();

        if (res.status === 200) {
            setServiceTypeCategories(data['service_type_categories']);
            console.log('loaded service type categories: ' + data['service_type_categories'].length);
        } 
        else if (res.status === 401) {
            console.log('logging out due to bad token (401)');
            onLogout();
        }
        else {
            console.log(JSON.stringify(data));
            setAlertError({
                title: data.name
            ,   body: data.description.replace(/\n/g, "<br />")
            });
        }

    }, [token, simulationInput]);

    useEffect(() => {
        fetchServiceTypes();
    }, [fetchServiceTypes, token]);

    return (
        <>
        <Alert show={alertError} variant="danger">
            <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
            <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
        </Alert>
        <div className="row">
            <div className="col-md-6" style={{ marginBottom: "1em" }}>
                <select className="form-select" id={ element_id + 'Category' } onChange={ handleOnChangeServiceTypeCategory } value={ simulationInput['serviceTypeCategory'] === null ? "": simulationInput['serviceTypeCategory']['service_type_category_id'] }>
                    <option value={ "null" } key={ element_id + 'Category' + '_null' }>Select Service Category...</option>
                    {serviceTypeCategories.map((t) => (
                        <option value={ t['service_type_category_id'] } key={ element_id + 'Category' + '_' + t['service_type_category_id'] }>{t['service_type_category']}</option>
                    ))}
                </select>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <select className="form-select" id={ element_id } onChange={ handleOnChangeServiceType } disabled={ simulationInput['serviceTypeCategory'] === null } value={ simulationInput['serviceType'] === null ? "": simulationInput['serviceType']['service_type_id'] }>
                    <option value={ "null" } key={ element_id + '_null' }>Select Service Type...</option>
                    {(simulationInput['serviceTypeCategory'] === null ? [] : simulationInput['serviceTypeCategory']['service_types']).filter((t) => {
                        return t['adult_pediatric'] === (simulationInput['adultPediatric']);
                    }).map((t) => (
                        <option value={ t['service_type_id'] } key={ element_id + '_' + t['service_type_id'] }>{t['service_type']}</option>
                    ))}
                </select>
            </div>
            <div className="col-md-6">
                <ButtonGroup>
                    {radiosAdultPediatric.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                        name="radio"
                        value={radio.value}
                        checked={simulationInput['adultPediatric'] === radio.value}
                        onChange={handleAdultPediatricChange}
                    >
                        {radio.name}
                    </ToggleButton>
                    ))}
                </ButtonGroup>
            </div>
        </div>
        </>
    );
}