import React from 'react';

export default function PageNotFound() {

    return (
        <div>
            <h2>Page Not Found</h2>
            <p>{window.location.pathname}</p>
        </div>
    );
}
