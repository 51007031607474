import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Alert from 'react-bootstrap/Alert';

export default function SimulationSeveritySelect({ element_id, token, onLogout, simulationInput, setSeverity }) {

    const [alertError, setAlertError] = useState(null)

    const severities_default = useMemo(
        () => {
            return {
                service_type_id: null
            ,   severities: []
            };
        },
        []
      );

    const [severities, setSeverities] = useState(severities_default);

    const handleOnChangeSeverity = async e => {
        if (e.target.value === "null") {
            setSeverity(null);
        } 
        else {
            const ids = e.target.value.split('_');

            const service_type_id = parseInt(ids[0]);
            const severity_id = parseInt(ids[1]);

            const s = severities['severities'].filter((severity) => severity['service_type_id'] === service_type_id && severity['severity_id'] === severity_id);
            setSeverity(s[0]);
            console.log('severity set to ' + JSON.stringify(s[0]));
        }
    };

    const fetchSeverities = useCallback(() => {
        const do_fetch = async () => {

            const res = await fetch('/api/v1/severity?' + new URLSearchParams({
                service_type_id: simulationInput['serviceType']['service_type_id']
            })
            , { method: 'GET', headers: { Authorization: 'Bearer ' + token }});
            const data = await res.json();
    
            if (res.status === 200) {
                setSeverities({
                    service_type_id: simulationInput['serviceType']['service_type_id']
                ,   severities: data['severity']
                });
                console.log('loaded severity_parameters for service_type ' + simulationInput['serviceType']['service_type_id'] + ': ' + data['severity'].length);
            } 
            else if (res.status === 401) {
                console.log('logging out due to bad token (401)');
                onLogout();
            }
            else {
                console.log(JSON.stringify(data));
                setAlertError({
                    title: data.name
                ,   body: data.description.replace(/\n/g, "<br />")
                });
            }
    
        }

        if (simulationInput['serviceType']) {
            if (simulationInput['serviceType']['service_type_id'] !== severities['service_type_id']) {
                do_fetch();
            }
        } else {
            setSeverities(severities_default);
        }
    }, [token, simulationInput, severities, severities_default]);

    useEffect(() => {
        fetchSeverities();
    }, [fetchSeverities, token, simulationInput])

    return (
        <>
        <Alert show={alertError} variant="danger">
            <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
            <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
        </Alert>
        <select className="form-select" id={ element_id } onChange={ handleOnChangeSeverity } disabled={ severities['severities'].length === 0 } value={ simulationInput['severity'] === null ? "": simulationInput['severity']['service_type_id'] + '_' + simulationInput['severity']['severity_id'] }>
            <option value={ "null" } key={ element_id + '_null' }>Select { simulationInput['serviceType'] ? `Clinical Severity for ${simulationInput['serviceType']['service_type']}` : '' }...</option>
            {severities['severities'].map((t) => (
                <option value={ t['service_type_id'] + '_' + t['severity_id'] } key={ element_id + '_' + t['service_type_id'] + '_' + t['severity_id'] }>{t['severity']}</option>
            ))}
        </select>
        </>
    );
}

