import React, { useCallback, useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import useToken from '../../hooks/useToken';


export default function Chat({ element_id, token, onLogout, chatContext }) {

    const [alertError, setAlertError] = useState(null)

    const [chatMessage, setChatMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);

    const [chatMessageSending, setChatMessageSending] = useState(false);

    const { tokenDecode } = useToken();
    console.log('Username:', tokenDecode['username']);

    const resetChat = useCallback(() => {

        const do_fetch = async () => {
            const res = await fetch('/api/v1/chatbot_reset', { method: 'GET', headers: { Authorization: 'Bearer ' + token }});
            
            if (res.status === 200) {
                console.log('reset chat');    
            } 
            else if (res.status === 401) {
                console.log('logging out due to bad token (401)');
                onLogout();
            }
            else { 
                setAlertError ({
                    title: 'Error Resetting Chat: '
                    , body: res.statusText
                })
                setTimeout(() => {setAlertError(null)}, 5000);
                console.error('Error resetting chat', res.statusText);
            }
        }

        do_fetch();

    }, [token, onLogout]);

    const setChatContext = useCallback( () => {
        
        const do_post = async () => {
            const res = await fetch('/api/v1/chatbot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                ,   'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({ 
                    chatbot_input: `Here is a little context about the questions I am asking: ${chatContext}`
                })
            });
    
            if (res.status === 200) {
                const data = await res.json();
                console.log('set chatbot context: ' + JSON.stringify(data));    
            } 
            else if (res.status === 401) {
                console.log('logging out due to bad token (401)');
                onLogout();
            }
            else if (res.status === 413) {
                setAlertError ({
                    title: 'Chat Error: '
                    , body: 'Initial token count exceeds token limit. Please refine your request.'
                })
                setTimeout(() => {setAlertError(null)}, 5000);
                console.error('Chat error: ', res.statusText);
                resetChat();
            }
            else {
                setAlertError ({
                    title: 'Chat Error: '
                    , body: res.statusText
                })
                setTimeout(() => {setAlertError(null)}, 5000);
                console.error('Chat error: ', res.statusText);
                resetChat();
            }
        }

        if (chatContext) {
            do_post();
        } else {
            console.log('no context defined');
        }

    }, [resetChat, token, onLogout, chatContext]);

    useEffect(() => {
        setChatContext();
    }, [setChatContext, resetChat, token, onLogout, chatContext]);
 
 
    const onClickSendChat = async () => {

        setChatMessage('');
        setChatMessageSending(true);

        const res = await fetch('/api/v1/chatbot', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            ,   'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({ 
                chatbot_input: chatMessage
            })
        });

        setChatMessageSending(false);

        if (res.status === 200) {
            const data = await res.json();
            console.log('received chat message: ' + JSON.stringify(data));
            setChatHistory((prevState) => [...prevState, data]);
        } 
        else if (res.status === 401) {
            console.log('logging out due to bad token (401)');
            onLogout();
        }
        else if (res.status === 413) {
            setAlertError ({
                title: 'Chat Error: '
                , body: 'Initial token count exceeds token limit. Please refine your request.'
            })
            setTimeout(() => {setAlertError(null)}, 5000);
            console.error('Chat error: ', res.statusText);
            resetChat();
        }
        else {
            setAlertError ({
                title: 'Chat Error: '
                , body: res.statusText
            })
            setTimeout(() => {setAlertError(null)}, 5000);
            console.error('Chat error: ', res.statusText);
            resetChat();
        }
    }

    const onKeyDownChat = (event) => {
        if (event.key === 'Enter') {
            onClickSendChat();
        }
      };

    return (
        <>
            <Alert show={alertError} variant="danger">
                <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
                <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
            </Alert>

            <Container>
            <Row>
                { chatHistory.map((h, h_idx) => (
                    <div key={ `chatbot_${h_idx}` }>
                    <Row className='mt-3'><b>{ tokenDecode['username'] }</b> { h['chatbot_input'] }</Row>
                    <Row className='mt-3' style={{'whiteSpace' : 'pre-wrap'}}><b>HealthRate</b> { h['chatbot_output'] }</Row>
                    <hr />
                    </div>
                ))}
            </Row>
            <Row className={ `mt-3 mb-3 mx-auto ${chatMessageSending ? '' : 'd-none' }` }><Spinner /></Row>
            </Container>
            <Row>
                <div className="input-group">
                    <span className="input-group-text">Chat</span>
                    <input type="text" className="form-control" value={ chatMessage } onChange={ (e) => setChatMessage(e.target.value) } id={ element_id + '_textFilter' } onKeyDown={ onKeyDownChat } autoComplete="off" />
                    <button className="btn btn-primary" onClick={ onClickSendChat }>Send</button>
                </div>
            </Row>
        </>
    );

}
