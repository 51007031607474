import React, { useState } from 'react';

import Offcanvas from 'react-bootstrap/Offcanvas';

import { Book, CashStack, Chat, DoorClosed, Globe, Link45deg, List, QuestionCircle, Search, Sim } from 'react-bootstrap-icons'

import logo from './assets/healthrate.io_horizontal.svg';

// import './fonts/montserrat.css'
// import './fonts/charis.css'
import './fonts/notosans.css'
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import FAQs from './components/FAQs/FAQs';
import Glossary from './components/Glossary/Glossary';
import InternationalQuote from './components/InternationalQuote/InternationalQuote';
import Login from './components/Login/Login';
import Support from './components/Support/Support';
import PageNotFound from './components/PageNotFound/PageNotFound';
import Simulation from './components/Simulation/Simulation';

import useSimulationResultsCache from './hooks/useSimulationResultsCache';
import useToken from './hooks/useToken';
import SimulationResults from './components/SimulationResults/SimulationResults';
import RateSheet from './components/RateSheet/RateSheet';
import ChatDialog from './dialogs/ChatDialog';

const FloatingChatBotIcon = ({ onClick }) => {
    return (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            cursor: 'pointer',
            width: '75px',
        }} onClick={onClick} className='d-print-none'>
            <button style={{"fontSize": "1.5rem"}} className="btn btn-info btn-lg"><Chat  /></button>
        </div>
    );
};

function App() {

    const { token, setToken, tokenDecode, tokenExpired } = useToken();
    const { addSimulationResult, simulationResultsCache } = useSimulationResultsCache();

    // ************************************************************************
    // Modals Chat

    const [modal_chatbot_dialog_open, setChatBotDialogOpen] = useState(false);
    const [modal_chatbot_context, setChatBotContext] = useState(null);

    const handleOnClickChatBot = () => {
        setChatBotDialogOpen(true);
    }

    // ************************************************************************
    // offcanvas menu (for mobile apps)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // ************************************************************************
    // start render

    if (!token || !tokenDecode || tokenExpired) {
        return <Login setToken={setToken} />
    }

    const onLogout = () => {
        setToken(null);
    }

    const handleOnClickLogout = async e => {
        e.preventDefault();
        onLogout();
    }

    return (

        <>

            <ChatDialog
                show={ modal_chatbot_dialog_open }
                onHide={ () => setChatBotDialogOpen(false) }
                size="xl"
                token={ token }
                onLogout={ onLogout }
                chatContext={ modal_chatbot_context }
            />

            <header className="navbar sticky-top bg-light flex-md-nowrap p-0 shadow d-md-none d-print-none">

                <div className="mx-auto d-block flex-row d-md-none px-3 py-3" href="/">
                    <a href="/"><img src={logo} className="img-responsive navbar-logo" alt="HealthRate Logo" /></a>
                </div>

                <ul className="navbar-nav flex-row d-md-none">
                    {/* <li className="nav-item text-nowrap">
                        <button className="nav-link px-3 text-white" type="button" onClick={() => setOpenSearch(!openSearch)} aria-controls="navbarSearch" aria-expanded={openSearch} aria-label="Toggle search">
                            <Search />
                        </button>
                    </li> */}
                    <li className="nav-item text-nowrap">
                        <button className="nav-link px-3 text-accent" type="button" onClick={handleShow} aria-controls="sidebarMenu" aria-expanded={show} aria-label="Toggle navigation">
                            <List />
                        </button>
                    </li>
                </ul>

                {/* <Collapse in={openSearch}>
                    <div id="navbarSearch" className="navbar-search w-100 collapse">
                        <input className="form-control w-100 rounded-0 border-0" type="text" placeholder="Search" aria-label="Search" />
                    </div>
                </Collapse> */}
            </header>

            <div className="container-fluid">

                <div className="row">

                    <div className="navbar-full-view sidebar border border-right col-md-3 col-lg-2 p-0 text-light bg-accent d-print-none">

                        <Offcanvas show={show} onHide={handleClose} responsive='md'>
                            <Offcanvas.Header closeButton>
                                {/* <Offcanvas.Title>HealthRate</Offcanvas.Title> */}
                            </Offcanvas.Header>
                            <Offcanvas.Body className='d-md-flex flex-column p-0 overflow-y-auto'>

                                <div className='nav bg-light mx-0' style={{borderRadius: "0px"}}>
                                    <div className="mx-auto px-3 py-3" >
                                        <a href="/"><img src={logo} className="img-responsive navbar-logo" alt="HealthRate Logo" /></a>
                                    </div>
                                </div>

                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/">
                                            <Sim />
                                            Simulation
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/results">
                                            <Search />
                                            Results
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/rate_sheets">
                                            <CashStack />
                                            Rate Sheets
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/international_quote">
                                            <Globe />
                                            International Quote
                                        </a>
                                    </li>

                                </ul>

                                <hr className="my-3" />

                                <ul className="nav flex-column mb-auto">
                                    {/* <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2" href="/">
                                            <GearWideConnected />
                                            Settings
                                        </a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/faqs">
                                            <QuestionCircle />
                                            FAQs
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/glossary">
                                            <Book />
                                            Glossary
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/support">
                                            <Link45deg />
                                            Support
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={handleOnClickLogout}>
                                        <a className="nav-link d-flex align-items-center gap-2" href="/">
                                            <DoorClosed />
                                            Sign out
                                        </a>
                                    </li>
                                </ul>

                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">

                        <div className='d-none d-print-block'><img src={logo} className="img-responsive navbar-logo mt-3" alt="HealthRate Logo" /></div>

                        <BrowserRouter>
                            <Routes>
                                <Route path="*" element={<PageNotFound />} />
                                <Route path="/" element={<Simulation token={token} onLogout={onLogout} addSimulationResult={addSimulationResult} />} />
                                <Route path="/faqs" element={<FAQs />} />
                                <Route path="/glossary" element={<Glossary />} />
                                <Route path="/international_quote" element={<InternationalQuote token={token} onLogout={onLogout}/>} />
                                <Route path="/rate_sheets" element={<RateSheet token={token} onLogout={onLogout} setChatBotContext={ setChatBotContext } />} />
                                <Route path="/rate_sheets/:provider_id/:network_id/:service_type_id" element={<RateSheet token={token} onLogout={onLogout} setChatBotContext={ setChatBotContext } />} />
                                <Route path="/results" element={<SimulationResults token={token} onLogout={onLogout} simulationResultsCache={simulationResultsCache} setChatBotContext={ setChatBotContext } />} />
                                <Route path="/results/:simulation_result_id" element={<SimulationResults token={token} onLogout={onLogout} simulationResultsCache={simulationResultsCache} setChatBotContext={ setChatBotContext } />} />
                                <Route path="/support" element={<Support />} />
                            </Routes>
                        </BrowserRouter>

                    </main>

                    { !modal_chatbot_dialog_open ? ( <FloatingChatBotIcon onClick={handleOnClickChatBot} />)  : ( <></> ) }

                </div>
            </div>
        </>

    );
}

export default App;
