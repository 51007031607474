import { useState } from 'react';
import { decodeToken, isExpired } from "react-jwt";

const dummy_token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjAsImlzcyI6IndlYiIsImF1ZCI6IndlYiIsImlhdCI6MCwidXNlcm5hbWUiOiJub3RfYV91c2VyIn0.hkssub6h1Bt4jPqzyUmYXe6wnQunqq2xhI_AQBMLyPibtNJiLqlwbZAfcRc0ipqIhOj16stGXaO9wiXn6KCufhbaPtQ7MlE_rzOLVKgKj9AYEVhr32n4vRJC2LxiSV4euPSAapzwvBc7D4JEt8Z9JTz21NB4yqUKgIy-dZkVkwdMGT-HFL3wDkDO89EWX2TcWnAlrT0Uf6HcuQBp4ife_2xbjJNbmB8fUhYgTm9t7w2W0YKY5jbQszijJejvjT0g8L_AD27nkk_as2m-lrGItn380-AG3OXgu8CzL74J10-hd49U8vVC7Afx-RO1_Er5-onIRctIyLUeCriIwcpQYhIGq78bBANK72gFgFYLEf-idi5YiDVGkQQNB0yyKVBuzNTB-ech6LVaHIRqxXErVASmmg7LwHVjiC4IHkfJuAJ1jpQd_sGQBPGEgbRqmMO_Tct8i9mFqUOFj_y8OIUGVw7EY0KL9ajPDCpzDp_WOirrGvD-O8awaNFZTMH7CrL6mOn6Fi_ftLtUUos_QcX_D-Dw2MV-DydWki6osMgd2_txWfdeVsy-_mTUd7J4MgVgjSrhmHhgohxVpB2PTBwNvAWZEtD5W4ektjXuJ4X1XQktrCiib8SVDJuceL0xfWOFrIShBjUH5Yx1hfP1vPaGfeD2f3ExQaaDkivWLkMgPEA';

export default function useToken() {

    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const tokenStored = JSON.parse(tokenString);
        return tokenStored ? tokenStored : dummy_token;
    };

    const [token, setToken] = useState(getToken());
    const [tokenDecode, setTokenDecode] = useState(token ? decodeToken(token) : null);
    const [tokenExpired, setTokenExpired] = useState(tokenDecode ? isExpired(token) :  true);

    const saveToken = tokenNew => {
        localStorage.setItem('token', JSON.stringify(tokenNew));
        setToken(tokenNew)
        setTokenDecode(tokenNew ? decodeToken(tokenNew) : null)
        setTokenExpired(tokenNew ? setTokenExpired(tokenNew) :  true)
    };

    return {
        setToken: saveToken
    ,   token
    ,   tokenDecode
    ,   tokenExpired
    }

}
