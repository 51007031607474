
export default function useFormatters() {

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    });
    
    const percentageFormatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        maximumFractionDigits: 0,
    });
    
    const numberFormatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0,
    });

    return {
        currencyFormatter
    ,   percentageFormatter
    ,   numberFormatter
    }
    
}
