import React from 'react';

import Row from 'react-bootstrap/Row';

export default function Glossary() {

    return (
        <div className='bg-light mt-4 mb-4 p-3'>
            <Row>
                <h1>Glossary</h1>
            </Row>
            <Row className='mt-3 mb-3 p-3'>
                <h3>Service Type</h3>

                <div class="table-responsive">
                    <table class="table text-left">

                    <tbody>
                        <tr>
                            <td scope='col' style={ {"width": "25%"} }><strong>Service Type</strong></td>
                            <td scope="col" style={ {"width": "75%"} }>The procedure or service to be performed / evaluated. Example "Transplant - Liver."</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Clinical Severity</strong>
                            </td>
                            <td>
                                Expressed as Low, Medium, High, and Catastrophic.  The expected clinical severity or complexity of the case. Corresponding field values will be defaulted to the selected level but remain modifiable.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Evaluation Cost</strong>
                            </td>
                            <td>
                                Expressed in dollars, any costs charged before case rates and contractual terms apply. Examples include charges for preliminary diagnosis or comorbidities.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Pre-Administration Cost</strong>
                            </td>
                            <td>
                                Expressed in dollars, all charges related to the case prior to administration of the therapy/transplant/procedure.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Transplant / Administration Cost</strong>
                            </td>
                            <td>
                                Expressed in dollars, all charges related to the case during administration of the therapy/transplant/procedure. Note not all costs are included in case rates.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Post-Administration Cost</strong>
                            </td>
                            <td>
                                Expressed in dollars, all charges related to the case after the administration of the therapy/transplant/procedure.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Additional Cost</strong>
                            </td>
                            <td>
                                Expressed in dollars, any costs excluded from case rates and contractual terms. Examples include charges for unrelated conditions, some follow ups or comorbidities.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Biologic</strong>
                            </td>
                            <td>
                                Expressed in dollars, the Wholesale Acquisition Cost "WAC" of the relevant therapy, commonly only relevant for novel therapies such as cell and gene.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Length of Stay</strong>
                            </td>
                            <td>
                                Expressed as a whole number. The number of days the patient is hospitalized.
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </Row>
            <Row className='mt-3 mb-3 p-3'>
                <h3>Providers and Networks</h3>

                <div class="table-responsive">
                    <table class="table text-left">

                    <tbody>
                        <tr>
                            <td scope='col' style={ {"width": "25%"} }><strong>Case Rate</strong></td>
                            <td scope="col" style={ {"width": "75%"} }>Expressed in dollars, the amount to be paid for the primary therapy/transplant/procedure.</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Facility Rate</strong>
                            </td>
                            <td>
                                Expressed as a percentage of charges, the rate at which all charges will be paid at (unless otherwise specified).
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Biologic Rate as a % of WAC</strong>
                            </td>
                            <td>
                                Expressed as a percentage of the Wholesale Acquisition Cost "WAC". The payment rate of the relevant therapy, commonly only relevant for novel therapies such as cell and gene.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Outlier Charge Threshold</strong>
                            </td>
                            <td>
                                Expressed in dollars, the amount (if any) at which charges will be paid at an alternative rate.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Outlier Rate % Charge</strong>
                            </td>
                            <td>
                                Expressed as a percentage of charges, the rate at which all charges above a certain threshold will be paid.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Minimum Payment Provision</strong>
                            </td>
                            <td>
                                Expressed as a percentage of charges, any provision that exists regarding overall minimum payments.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Inlier Days</strong>
                            </td>
                            <td>
                                Expressed as a whole number. The number of days included in the case rate.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Per Diem</strong>
                            </td>
                            <td>
                                Expressed in dollars, the amount (if any) at which hospitalization will be covered for days beyond the Inlier Days.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Escalator</strong>
                            </td>
                            <td>
                                Expressed as a percentage, the rate at which the Case Rate is periodically increased due to inflation or contract terms.
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </Row>
            <Row className='mt-3 mb-3 p-3'>
                <h3>General</h3>

                <div class="table-responsive">
                    <table class="table text-left">

                    <tbody>
                        <tr>
                            <td scope='col' style={ {"width": "25%"} }><strong>PM/PM</strong></td>
                            <td scope="col" style={ {"width": "75%"} }>Per Member Per Month</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>PE/PM</strong>
                            </td>
                            <td>
                                Per Employee Per Month
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>PM/PY</strong>
                            </td>
                            <td>
                                Per Member Per Year
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>PE/PY</strong>
                            </td>
                            <td>
                                Per Employee Per Year
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>CAGR</strong>
                            </td>
                            <td>
                                Compound Annual Growth Rate
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>TAM</strong>
                            </td>
                            <td>
                                Total Addressable Market. Used to explain the potential size of the opportunity.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>AI</strong>
                            </td>
                            <td>
                                Artificial Intelligence (AI) refers to technology that enables computers and machines to simulate human intelligence and problem-solving capabilities. It encompasses various disciplines, including Machine Learning and Deep Learning.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Machine Learning</strong>
                            </td>
                            <td>
                                Machine learning (ML) is a subfield of Artificial Intelligence (AI) that uses algorithms trained on data sets to create self-learning models capable of predicting outcomes and classifying information without human intervention. It enables machines to perform tasks that would otherwise only be possible for humans, such as categorizing images, analyzing data, or predicting price fluctuations.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Deep Learning</strong>
                            </td>
                            <td>
                                Deep Learning is a subfield of Machine Learning (ML) that focuses on using artificial neural networks with multiple layers (deep networks) to model complex patterns in large datasets. It enables systems to automatically learn from vast amounts of data without the need for explicit programming of feature extraction.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>TIC</strong>
                            </td>
                            <td>
                                The Transparency in Coverage Act was finalized on October 29, 2020 by the Departments of Health and Human Services (HHS), Labor, and the Treasury. This historic rule aims to put health care price information in the hands of consumers and other stakeholders, advancing the goal of ensuring that consumers are empowered with critical information to make informed health care decisions.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>No Surprises</strong>
                            </td>
                            <td>
                                The No Surprises Act was signed into law on December 27, 2020, as part of the Consolidated Appropriations Act of 2021. This legislation aims to protect patients from excessive surprise medical bills by prohibiting certain billing practices. It partially went into effect at the beginning of 2022 and includes provisions that ban surprise bills for most emergency services, limit out-of-network cost-sharing, and establish an independent dispute resolution process for payment disputes between plans and providers
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </Row>
        </div>
    )

}